import React, { useEffect, useState } from "react";
import rocketPNG from "../../HomePage/Assets/Images/Vectary texture.png";
import "./PracticalInterviewFeedback.css";
import Loader from "../../components/Loader/Loader";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { useSelector } from "react-redux";

function PracticalInterviewFeedback({
  question,
  answer,
  recomendations,
  candidateAnswers,
  videoId,
}) {
  var [scores, setScores] = useState([]);
  var [recomendationsChatgpt, setRecomendationsChatgpt] = useState([]);
  const [loading, setLoading] = useState(true);

  // const token = useSelector((state) => state?.token?.token);

  const token = localStorage.getItem("Token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      if (videoId !== "") {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VIDEO_DATA}/${videoId}`,
            config
          );
          const data = await response.json();
          console.log("data from backend", data);
          setRecomendationsChatgpt(data.recomendationsChatgpt || []);
          setScores(data.scores || []);
          setLoading(false);
          if (!data.recomendationsChatgpt || !data.scores) {
            setTimeout(fetchData, 5000);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    };
    fetchData();
    return () => clearTimeout(fetchData);
  }, [videoId]);

  return (
    <>
      {loading ? (
        <Loader visible={true} style={{ flex: 1 }} />
      ) : (
        <div className="h-100 w-100 d-flex flex-column align-items-center py-3">
          <div className="w-75 blue-background shadow-container d-flex flex-row align-items-center justify-content-center px-4 ">
            <div className="d-flex flex-column align-items-start w-75 p-2">
              <h5 className="heading-secondary text-white">
                Feedback (Practice Interview)
              </h5>
              <p className="para-tertiary text-white">
                Welcome to AI-Enhanced Interview Practice, your gateway to a
                smarter, more effective approach to interview preparation
                tailored to your industry.
              </p>
              {/* <h5 className="heading-secondary text-white">Total Score: 8/10</h5> */}
            </div>
            <div className="d-flex flex-column align-items-start  ">
              <img src={rocketPNG} alt="Rocket" />
            </div>
          </div>
          {scores && scores.length > 0 && (
            <div className="d-flex flex-column w-75 align-items-center justify-content-center pt-4">
              <p className="overview-text">Overview</p>
              <p>{scores[0].split(":")[1]}</p>

              <div className="d-flex flex-row align-items-center justify-content-center ">
                {scores.map((score, index) => {
                  if (index !== 0) {
                    return (
                      <div className="d-flex flex-column result-box blue-text blue-bg align-items-center me-2 justify-content-center">
                        <p className="result-type  my-0">
                          {score.split(":")[0]}
                        </p>
                        <p className="result-percentage my-0">
                          {score.split(":")[1].split("0")[0]}0
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

          <div className="d-flex flex-column align-items-start  w-75 pt-4">
            {recomendationsChatgpt && recomendationsChatgpt.length > 0 ? (
              <div className="mb-4">
                <div className="p-2 pb-0">
                  {recomendationsChatgpt.length > 1 && (
                    <div className="d-flex flex-column justify-content-center">
                      <h5 className="heading-primary blue-color">
                        Recommendations:
                      </h5>
                      <ul className="list-unstyled">
                        {recomendationsChatgpt.slice(1).map((rec, index) => (
                          <div
                            className="tip-container d-flex flex-row align-items-center mb-3"
                            key={index}
                            style={{
                              borderLeft: "5px solid #1E3C7A",
                              borderRadius: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            <li key={index + 1}>
                              {index + 1}: {rec}
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <SpinnerLoader />
            )}
            <h5 className="heading-primary blue-color">Tips</h5>
            <p className="result-percentage">
              Suggestions to impove your answers
            </p>

            {question &&
              question.map((questionText, index) =>
                recomendations[index] != null && answer[index] != null ? (
                  <div
                    className="tip-container d-flex flex-row align-items-center mb-3"
                    key={index}
                    style={{
                      borderLeft: "5px solid #1E3C7A",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* <img src={tipBorder} className="tip-border me-2" /> */}
                    <div className="d-flex flex-column justify-content-center">
                      <p className="tip-heading my-0">
                        Question {index + 1}: {questionText}
                      </p>
                      <p className="para-tertiary my-0 text-success">
                        <strong>Recommendation:</strong> {recomendations[index]}
                      </p>
                      <p className="para-tertiary my-0">
                        <strong>Candidate Answer:</strong>{" "}
                        {candidateAnswers[index]}
                      </p>

                      <p className="para-tertiary my-0">
                        <strong>Answer:</strong> {answer[index]}
                      </p>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default PracticalInterviewFeedback;

// import React, { useEffect, useState } from "react";
// import rocketPNG from "../../Assets/Images/Vectary texture.png";
// import "./PracticalInterviewFeedback.css";
// import Loader from "../../Components/Loader/Loader";
// import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
// import { useSelector } from "react-redux";

// function PracticalInterviewFeedback({
//   question,
//   answer,
//   recomendations,
//   candidateAnswers,
//   videoId,
// }) {
//   var [scores, setScores] = useState([]);
//   var [recomendationsChatgpt, setRecomendationsChatgpt] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const token = useSelector((state) => state.token.token);

//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       if (videoId !== "") {
//         try {
//           const response = await fetch(
//             `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VIDEO_DATA}/${videoId}`,
//             config
//           );
//           const data = await response.json();
//           console.log("data from backend", data);
//           setRecomendationsChatgpt(data.recomendationsChatgpt || []);
//           setScores(data.scores || []);
//           setLoading(false);
//           if (!data.recomendationsChatgpt || !data.scores) {
//             setTimeout(fetchData, 5000);
//           }
//         } catch (error) {
//           console.error("Error fetching data:", error);
//           setLoading(false);
//         }
//       }
//     };
//     fetchData();
//     return () => clearTimeout(fetchData);
//   }, [videoId]);

//   return (
//     <>
//       {scores.length > 0 || recomendationsChatgpt.length > 0 ? (
//         <Loader visible={true} style={{ flex: 1 }} />
//       ) : (
//         <div className="h-100 w-100 d-flex flex-column align-items-center py-3">
//           <div className="w-75 blue-background shadow-container d-flex flex-row align-items-center justify-content-center px-4 ">
//             <div className="d-flex flex-column align-items-start w-75 p-2">
//               <h5 className="heading-secondary text-white">
//                 Feedback (Practice Interview)
//               </h5>
//               <p className="para-tertiary text-white">
//                 Welcome to AI-Enhanced Interview Practice, your gateway to a
//                 smarter, more effective approach to interview preparation
//                 tailored to your industry.
//               </p>
//               {/* <h5 className="heading-secondary text-white">Total Score: 8/10</h5> */}
//             </div>
//             <div className="d-flex flex-column align-items-start  ">
//               <img src={rocketPNG} alt="Rocket" />
//             </div>
//           </div>
//           {scores && scores.length > 0 && (
//             <div className="d-flex flex-column w-75 align-items-center justify-content-center pt-4">
//               <p className="overview-text">Overview</p>
//               <p>{scores[0].split(":")[1]}</p>

//               <div className="d-flex flex-row align-items-center justify-content-center ">
//                 {scores.map((score, index) => {
//                   if (index !== 0) {
//                     return (
//                       <div className="d-flex flex-column result-box blue-text blue-bg align-items-center me-2 justify-content-center">
//                         <p className="result-type  my-0">
//                           {score.split(":")[0]}
//                         </p>
//                         <p className="result-percentage my-0">
//                           {score.split(":")[1].split("0")[0]}0
//                         </p>
//                       </div>
//                     );
//                   }
//                 })}
//               </div>
//             </div>
//           )}

//           <div className="d-flex flex-column align-items-start  w-75 pt-4">
//             {recomendationsChatgpt && recomendationsChatgpt.length > 0 ? (
//               <div className="mb-4">
//                 <div className="p-2 pb-0">
//                   {recomendationsChatgpt.length > 1 && (
//                     <div className="d-flex flex-column justify-content-center">
//                       <h5 className="heading-primary blue-color">
//                         Recommendations:
//                       </h5>
//                       <ul className="list-unstyled">
//                         {recomendationsChatgpt.slice(1).map((rec, index) => (
//                           <div
//                             className="tip-container d-flex flex-row align-items-center mb-3"
//                             key={index}
//                             style={{
//                               borderLeft: "5px solid #1E3C7A",
//                               borderRadius: "5px",
//                               paddingLeft: "10px",
//                             }}
//                           >
//                             <li key={index + 1}>
//                               {index + 1}: {rec}
//                             </li>
//                           </div>
//                         ))}
//                       </ul>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               <SpinnerLoader />
//             )}
//             <h5 className="heading-primary blue-color">Tips</h5>
//             <p className="result-percentage">
//               Suggestions to impove your answers
//             </p>

//             {question.map((questionText, index) =>
//               recomendations[index] != null && answer[index] != null ? (
//                 <div
//                   className="tip-container d-flex flex-row align-items-center mb-3"
//                   key={index}
//                   style={{
//                     borderLeft: "5px solid #1E3C7A",
//                     borderRadius: "5px",
//                     paddingLeft: "10px",
//                   }}
//                 >
//                   {/* <img src={tipBorder} className="tip-border me-2" /> */}
//                   <div className="d-flex flex-column justify-content-center">
//                     <p className="tip-heading my-0">
//                       Question {index + 1}: {questionText}
//                     </p>
//                     <p className="para-tertiary my-0 text-success">
//                       <strong>Recommendation:</strong> {recomendations[index]}
//                     </p>
//                     <p className="para-tertiary my-0">
//                       <strong>Candidate Answer:</strong>{" "}
//                       {candidateAnswers[index]}
//                     </p>

//                     <p className="para-tertiary my-0">
//                       <strong>Answer:</strong> {answer[index]}
//                     </p>
//                   </div>
//                 </div>
//               ) : null
//             )}
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default PracticalInterviewFeedback;
