/* eslint-disable */

import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { MenuButton, MenuList, MenuItem, HStack } from '@chakra-ui/react'; // Chakra UI components
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { useState, useEffect } from 'react';
import axios from 'axios';
const columnHelper = createColumnHelper();

// const columns = columnsDataCheck;
export default function ColumnTable(props) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  let defaultData = tableData;
  // Step 1: Define the state for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Step 2: Create a function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle between true and false
  };

  const columns = [
    columnHelper.accessor((row, rowIndex) => rowIndex + 1, {
      id: 'sino',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          SI.NO
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('title', {
      id: 'title',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Job Title
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Location
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('type', {
      id: 'type',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Job Type
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    // columnHelper.accessor('status', {
    //   id: 'status',
    //   header: () => (
    //     <Text
    //       justifyContent="space-between"
    //       align="center"
    //       fontSize={{ sm: '10px', lg: '12px' }}
    //       color="gray.400"
    //     >
    //       Status
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Text color={textColor} fontSize="sm" fontWeight="700">
    //       {info.getValue()}
    //     </Text>
    //   ),
    // }),

    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Actions
        </Text>
      ),

      cell: (info) => (
        <Flex justifyContent="space-between" align="center">
          <>
            <Button
              color="yellow"
              style={{ backgroundColor: 'green' }}
              fontSize="sm"
              fontWeight="700"
              onClick={toggleModal} // Open modal when clicked
            >
              Share
            </Button>

            {/* Modal */}
            {isModalOpen && (
              <div className="modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Interview Invitation
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={toggleModal} // Close modal on click
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="d-flex flex-column mb-3 align-items-center justify-content-center border-bottom pb-3">
                        <form className="w-100 d-flex flex-column align-items-start">
                          <label className="me-3 mb-1">Send invites to:</label>
                          <input
                            type="text"
                            className="form-control w-100 mb-2"
                            placeholder="Enter number or email"
                          />
                          <label className="me-3 mb-1">Email Content:</label>
                          <textarea
                            rows={4}
                            className="form-control w-100 shadow-none outline-none mb-2"
                            placeholder="Write your email content here"
                          />
                        </form>
                        <div className="w-100 d-flex flex-row align-items-center justify-content-end my-3">
                          <button className="btn-success btn w-25">Send</button>
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center w-100 justify-content-center">
                        <label className="me-3">Job URL:</label>
                        <input
                          style={{ cursor: 'pointer' }}
                          type="text"
                          className="form-control w-75"
                          readOnly
                          value="https://example.com/job-url" // Static URL placeholder
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
          <Button
            color={textColor}
            style={{ backgroundColor: 'orange' }}
            fontSize="sm"
            fontWeight="700"
          >
            <select  style={{ backgroundColor: 'orange' }}>
              <option>Active</option>
              <option>InActive</option>
              <option>Hold</option>
              <option>Reject</option>
            </select>
          </Button>
          <Button
            color={textColor}
            style={{ backgroundColor: 'blue',color:"#fff" }}
            fontSize="sm"
            fontWeight="700"
          >
            Edit
          </Button>
          <Button
            color={textColor}
            style={{ backgroundColor: 'red' }}
            fontSize="sm"
            fontWeight="700"
          >
            Delete
          </Button>
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [jobs, setJobs] = useState([]);
  const token = localStorage.getItem('Token');

  const getJobs = async () => {
    try {
      //REACT_APP_GET_JOBS
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data.jobs);
      setData(data?.jobs);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getJobs();
  }, []);
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Posted Jobs
        </Text>
        <Menu />
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 11)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
