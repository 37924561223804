import { configureStore } from "@reduxjs/toolkit";
import roleSlice from "./roleSlice";
import tokenSlice from "./tokenSlice";

const store = configureStore({
    reducer: {
        token: tokenSlice,
        role: roleSlice
    }
})

export default store
