import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu } from 'antd';
import {
  Avatar,
  Box,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePauseCircle,
  MdOutlineDashboard,
  MdCancel,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Jobs from './Jobs';
import axios from 'axios';
import { Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Candidates = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobs, setJobs] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    Active: 0,
    Inactive: 0,
    Hold: 0,
    Closed: 0,
  });
  useEffect(() => {
    getJobs();
  }, []);
  const token = localStorage.getItem('Token');

  const getJobs = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const active = data?.jobs?.filter((job) => job.status === 'Active');

      // console.log(active);
      setJobs(active);
    } catch (error) {
      console.log(error);
    }
  };

  const ActionsMenu = () => {
    const menu = (
      <Menu>
        <Menu.Item key="1">Action 1</Menu.Item>
        <Menu.Item key="2">Action 2</Menu.Item>
      </Menu>
    );

    // return (
    //   <Dropdown overlay={menu}>
    //     <a href="#">
    //       More <span className="anticon anticon-down" />
    //     </a>
    //   </Dropdown>
    // );
  };

  const ExpandedTable = ({ data }) => {
    const columns = [
      // { title: 'Date', dataIndex: 'date', key: 'date' },
      { title: 'Name', dataIndex: 'firstName', key: 'firstName' },
      { title: 'E-Mail', dataIndex: 'email', key: 'email' },
      {
        title: 'Location',
        dataIndex: 'currentLocation',
        key: 'currentLocation',
      },
      { title: 'CV Score', dataIndex: 'cvScore', key: 'cvScore' },
      // { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },

      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, record) => (
          <Flex justifyContent="space-evenly" align="center">
            <a href={record.pdf} target="_blank">
              <Button
                color={textColor}
                style={{ backgroundColor: 'blue', color: 'white' }}
                fontSize="sm"
                fontWeight="700"
              >
                View CV
              </Button>{' '}
            </a>

            <Button
              color={textColor}
              style={{ backgroundColor: 'green', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
            >
              Accept
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'red', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
            >
              Reject
            </Button>
            <Button
              color={textColor}
              style={{ backgroundColor: 'orange', color: 'white' }}
              fontSize="sm"
              fontWeight="700"
            >
              Hold
            </Button>
            <ActionsMenu />
          </Flex>
        ),
      },
    ];

    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const NestedTable = ({ tab }) => {
    // const filterCandidatesByTab = (job) => {
    //   return job.candidates.filter(
    //     (candidate) => candidate.status === tab
    //   );
    // };
    const [jobCandidates, setJobCandidates] = useState([]);

    useEffect(() => {
      fetchCandidatesForActiveJobs();
    }, [tab, jobs]);

    const fetchCandidatesForActiveJobs = async () => {
      const token = localStorage.getItem('Token');
      const updatedJobCandidates = [];

      for (const job of jobs) {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/candidate/new/${job._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          updatedJobCandidates.push({
            jobId: job._id,
            jobTitle: job.title,
            candidates: data.candidates || [], // assuming the API returns a 'candidates' array
          });
          // console.log(updatedJobCandidates);
        } catch (error) {
          console.error(
            `Failed to fetch candidates for job ${job.jobId}:`,
            error,
          );
        }
      }
      setJobCandidates(updatedJobCandidates);
    };

    const filteredData = jobs.map((job, idx) => ({
      key: job._id,
      jobTitle: job.title,
      sino: idx + 1,
      // candidates: filterCandidatesByTab(job),
    }));
    const mainColumns = [
      {
        title: 'SI.NO',
        dataIndex: 'sino',
        key: 'sino',
        render: (_, __, index) => index + 1,
      },
      { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
      // { title: 'Version', dataIndex: 'version', key: 'version' },
      // { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
      // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
      // { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   render: () => <a href="#">Publish</a>,
      // },
    ];

    // Sample data filtered by tab selection
    // const dataByTab = {
    //   active: [
    //     {
    //       key: 1,
    //       sino: 1,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   hold: [
    //     {
    //       key: 2,
    //       sino: 2,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   accept: [
    //     {
    //       key: 3,
    //       sino: 3,
    //       jobTitle: 'Devops Engineer',
    //     },
    //   ],
    //   reject: [],
    // };

    // const mainData = dataByTab[tab] || [];
    // console.log(filteredData);

    return (
      <Table
        className="components-table-demo-nested"
        columns={mainColumns}
        expandedRowRender={(record) => (
          <ExpandedTable data={record.candidates} />
        )}
        dataSource={jobCandidates}
      />
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Search & Invite Candidates</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <div className="report-box" style={{}}>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineDashboard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Accept"
                  value="2"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePauseCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Hold"
                  value="1"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCancel}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Reject"
                  value="3"
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                {/* <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Active
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Hold
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="accept" style={{ color: 'blue' }}>
                    Accept
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Reject
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
            </Card.Header>
            <Card.Body>
              <NestedTable tab={activeTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Candidates;
