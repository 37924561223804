import React,{useEffect} from 'react';
import './NewHomePage.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { MDBIcon } from 'mdb-react-ui-kit';
import img from '../assets/img/logo.png';
import Home from '../assets/img/illustration-1.png';
import img1 from '../assets/img/avatar-1.png';
import img2 from '../assets/img/avatar-2.png';
import img3 from '../assets/img/avatar-3.png';
import img4 from '../assets/img/avatar-4.png';
import img5 from '../assets/img/avatar-5.png';
import about1 from '../assets/img/about-5.png';
import about2 from '../assets/img/about-2.png';
import features1 from '../assets/img/features-illustration-1.png';
import features2 from '../assets/img/features-illustration-2_1.png';
import features3 from '../assets/img/features-illustration-3.png';
import phone from '../assets/images/freepik_br_3cb79d52-95b2-4209-b603-fe3a350c8644.png';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container as ContainerBase } from '../components/misc/Layouts';
// import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../images/icons8-twitterx.svg';
import { ReactComponent as YoutubeIcon } from '../images/youtube-icon.svg';
import per1 from '../assets/img/testimonials/testimonials-1.jpg';
import per2 from '../assets/img/testimonials/testimonials-2.jpg';
import per3 from '../assets/img/testimonials/testimonials-3.jpg';
import per4 from '../assets/img/testimonials/testimonials-4.jpg';
import { useNavigate } from 'react-router-dom';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl text-blue-600 font-bold tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

// import SwiperCore, { Autoplay, Pagination } from 'swiper/modules';

// SwiperCore.use([Autoplay, Pagination]);

const NewHomePage = () => {
  const token = localStorage.getItem('Token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/admin/default');
    }
  }, []);
  // SwiperCore.use([Autoplay, Pagination]);
  const swiperConfig = {
    modules: [Autoplay, Pagination], // Specify the modules here
    loop: true,
    speed: 600,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 'auto',
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      320: { slidesPerView: 2, spaceBetween: 40 },
      480: { slidesPerView: 3, spaceBetween: 60 },
      640: { slidesPerView: 4, spaceBetween: 80 },
      992: { slidesPerView: 6, spaceBetween: 120 },
    },
  };
  return (
    <div class="index-page">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="header-container container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="/" class="logo d-flex align-items-center me-auto me-xl-0">
            <div style={{ fontSize: '25px', fontWeight: '800' }}>
              Firstlevel<span style={{ color: 'orange' }}>AI</span>
            </div>
          </a>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <a href="#hero" class="active">
                  Home
                </a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              {/* <li class="dropdown">
                <a href="#">
                  <span>Dropdown</span>{' '}
                  <i class="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li class="dropdown">
                    <a href="#">
                      <span>Deep Dropdown</span>{' '}
                      <i class="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li> */}
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
          <ul style={{ display: 'flex' }}>
            <li>
              <a class="btn-getstarted" href="/login">
                Login
              </a>
            </li>
            <li>
              <a class="btn-getstarted" href="/SignUp">
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </header>

      <main class="main">
        <section id="hero" class="hero section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div
                  class="hero-content"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div class="company-badge mb-4">
                    <i class="bi bi-gear-fill me-2"></i> Working for your
                    success
                  </div>
                  <h1 class="mb-4">
                    Simplify Your Hiring
                    <br />
                    <span class="accent-text">with AI Video Interview </span>
                  </h1>
                  <p class="mb-4 mb-md-5">
                    Discover the future of recruitment. Simplify your hiring
                    process with AI. Schedule and post jobs using AI-powered
                    video interviews for efficient and effective recruitment.
                  </p>
                  <div class="hero-buttons">
                    <a href="/signUp" class="btn btn-primary me-0 me-sm-2 mx-1">
                      Sign Up
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                      class="btn btn-link mt-2 mt-sm-0 glightbox"
                    >
                      <i class="bi bi-play-circle me-1"></i> Play Video
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="hero-image"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <img src={Home} alt="Hero Image" class="img-fluid" />
                  <div class="customers-badge">
                    <div class="customer-avatars">
                      <img src={img1} alt="Customer 1" class="avatar" />
                      <img src={img2} alt="Customer 2" class="avatar" />
                      <img src={img3} alt="Customer 3" class="avatar" />
                      <img src={img4} alt="Customer 4" class="avatar" />
                      <img src={img5} alt="Customer 5" class="avatar" />
                      <span class="avatar more">12+</span>
                    </div>
                    <p class="mb-0 mt-2">
                      Join over 12,000 users leveraging our AI-driven video
                      assessment for a smoother, more interactive interview
                      process.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row stats-row gy-4 mt-5"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div class="col-lg-3 col-md-6">
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-camera-video"></i>
                  </div>
                  <div class="stat-content">
                    <h4>500+ </h4>
                    <p class="mb-0">Interviews Conducted</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-people"></i>
                  </div>
                  <div class="stat-content">
                    <h4>1,200+</h4>
                    <p class="mb-0"> Candidates Assessed</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-check-circle"></i>
                  </div>
                  <div class="stat-content">
                    <h4>98% </h4>
                    <p class="mb-0">Completion Rate</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-trophy"></i>
                  </div>
                  <div class="stat-content">
                    <h4>3x</h4>
                    <p class="mb-0">Industry Awards</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" class="about section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row gy-4 align-items-center justify-content-between">
              <div class="col-xl-5" data-aos="fade-up" data-aos-delay="200">
                <span class="about-meta">About FirstlevelAI</span>
                <h2 class="about-title">
                  We are a modern AI Video Interview Company.
                </h2>
                <p class="about-description">
                  At Firstlevel AI, we are revolutionizing the recruitment
                  landscape with our cutting-edge AI-driven video interview
                  solutions. Our mission is to streamline the hiring process for
                  companies and candidates alike, making it more efficient,
                  fair, and insightful.
                </p>
                <div class="row feature-list-wrapper">
                  <div class="col-md-6">
                    <ul class="feature-list">
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Cutting-edge AI
                        Solutions
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Expert Team of
                        AI Engineers
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Scalable and
                        Secure Platforms
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="feature-list">
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Real-time Data
                        Analysis
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> User-focused
                        Design and UX
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Continuous
                        Innovation in AI
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="info-wrapper">
                  <div class="row gy-4">
                    <div class="col-lg-5">
                      <div class="profile d-flex align-items-center gap-3">
                        <img
                          src={img1}
                          alt="CEO Profile"
                          class="profile-image"
                        />
                        <div>
                          <h4 class="profile-name">Mario Smith</h4>
                          <p class="profile-position">CEO &amp; Founder</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <div class="contact-info d-flex align-items-center gap-2">
                        <i class="bi bi-telephone-fill"></i>
                        <div>
                          <p class="contact-label">Call us anytime</p>
                          <p class="contact-number">+123 456-789</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6" data-aos="fade-up" data-aos-delay="300">
                <div class="image-wrapper">
                  <div
                    class="images position-relative"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <img
                      src={about1}
                      alt="Business Meeting"
                      class="img-fluid main-image rounded-4"
                    />
                    <img
                      src={about2}
                      alt="Team Discussion"
                      class="img-fluid small-image rounded-4"
                    />
                  </div>
                  <div class="experience-badge floating">
                    <h3>
                      15+ <span>Years</span>
                    </h3>
                    <p>Of experience in business service</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" class="features section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Key Events and Insights</h2>
            <p>
              Here are some of the most popular events by AI Video Interviews by
              professionals.
            </p>
          </div>

          <div class="container">
            <div class="d-flex justify-content-center">
              <ul class="nav nav-tabs" data-aos="fade-up" data-aos-delay="100">
                <li class="nav-item">
                  <a
                    class="nav-link active show"
                    data-bs-toggle="tab"
                    data-bs-target="#features-tab-1"
                  >
                    <h4>Fairness</h4>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#features-tab-2"
                  >
                    <h4>Assessments</h4>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#features-tab-3"
                  >
                    <h4>Resumes</h4>
                  </a>
                </li>
              </ul>
            </div>

            <div class="tab-content" data-aos="fade-up" data-aos-delay="200">
              <div class="tab-pane fade active show" id="features-tab-1">
                <div class="row">
                  <div class="col-lg-6 details">
                    <h3>Fair Hiring Practices</h3>
                    <p class="fst-italic">
                      Maintaining ethics and fairness in AI hiring: AI
                      automation offers many benefits, but it's vital to combine
                      it with human oversight to ensure fair and unbiased hiring
                      decisions in video interviews.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> AI-driven
                        assessments are rigorously tested to minimize biases.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Human oversight
                        ensures fair interpretation of AI recommendations.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Transparent
                        algorithms promote trust and compliance in hiring.
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 text-center">
                    <img
                      src={features1}
                      alt="Feature Image 1"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="features-tab-2">
                <div class="row">
                  <div class="col-lg-6 details">
                    <h3>Advanced Candidate Assessments</h3>
                    <p class="fst-italic">
                      By leveraging natural language processing and facial
                      recognition technology, AI video interviews analyze
                      candidate responses and non-verbal cues, aiding in
                      comprehensive candidate assessment.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Analyzes speech
                        patterns to evaluate communication skills and
                        confidence.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Detects
                        non-verbal cues like facial expressions for emotional
                        intelligence insights.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Generates
                        objective scores to support unbiased candidate
                        evaluations.
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 text-center">
                    <img
                      src={features2}
                      alt="Feature Image 2"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="features-tab-3">
                <div class="row">
                  <div class="col-lg-6 details">
                    <h3>Beyond Traditional Resumes</h3>
                    <p class="fst-italic">
                      Deep Insights Through AI Video Interviews: AI video
                      interviews offer innovative screening methods that provide
                      valuable insights beyond what traditional resumes can
                      show.
                    </p>
                    <ul>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Identifies soft
                        skills and personality traits not visible on resumes.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Analyzes
                        candidate adaptability and problem-solving through
                        real-time responses.
                      </li>
                      <li>
                        <i class="bi bi-check-circle-fill"></i> Delivers
                        data-driven insights to enhance hiring decisions.
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 text-center">
                    <img
                      src={features3}
                      alt="Feature Image 3"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features-2" class="features-2 section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div
                  class="feature-item text-end mb-5"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div class="d-flex align-items-center justify-content-end gap-4">
                    <div class="feature-content">
                      <h3>Device Flexibility</h3>
                      <p>
                        Conduct video assessments seamlessly across multiple
                        devices, ensuring accessibility and flexibility for
                        candidates.
                      </p>
                    </div>
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-display"></i>
                    </div>
                  </div>
                </div>

                <div
                  class="feature-item text-end mb-5"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <div class="d-flex align-items-center justify-content-end gap-4">
                    <div class="feature-content">
                      <h3>High-Resolution Quality</h3>
                      <p>
                        Ensure clarity in video and audio for accurate
                        assessments, with support for high-resolution video
                        capturing.
                      </p>
                    </div>
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-feather"></i>
                    </div>
                  </div>
                </div>

                <div
                  class="feature-item text-end"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <div class="d-flex align-items-center justify-content-end gap-4">
                    <div class="feature-content">
                      <h3>AI-Powered Analytics</h3>
                      <p>
                        Use AI to analyze responses for sentiment, engagement,
                        and relevant competencies, making data-driven decisions
                        easier.
                      </p>
                    </div>
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-code-square"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                <div class="phone-mockup text-center">
                  <img src={phone} alt="Phone Mockup" class="img-fluid" />
                </div>
              </div>

              <div class="col-lg-4">
                <div
                  class="feature-item mb-5"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div class="d-flex align-items-center gap-4">
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-eye"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Real-Time Feedback</h3>
                      <p>
                        Provide real-time feedback to candidates or reviewers,
                        enhancing the evaluation experience and streamlining the
                        assessment process.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="feature-item mb-5"
                  data-aos="fade-left"
                  data-aos-delay="300"
                >
                  <div class="d-flex align-items-center gap-4">
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-browser-chrome"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Cross-Browser Compatibility</h3>
                      <p>
                        Seamlessly compatible with all major browsers to offer
                        uninterrupted assessments, no matter the platform.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="feature-item"
                  data-aos="fade-left"
                  data-aos-delay="400"
                >
                  <div class="d-flex align-items-center gap-4">
                    <div class="feature-icon flex-shrink-0">
                      <i class="bi bi-phone"></i>
                    </div>
                    <div class="feature-content">
                      <h3>Behavioral Insights</h3>
                      <p>
                        Gain insights into candidate behavior through facial
                        expressions, tone analysis, and non-verbal cues captured
                        during video assessments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="call-to-action" class="call-to-action section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row justify-content-center align-items-center position-relative">
              <div class="col-lg-8 mx-auto text-center">
                <h2 class="display-4 mb-4">
                  Enhance Your Hiring Process with AI Video Assessments
                </h2>
                <p class="mb-4">
                  Use our AI-powered video assessments to gain deeper insights
                  into candidates' skills and communication style. Optimize your
                  recruitment with automated analysis and customized feedback.
                </p>
                <a href="#" class="btn btn-cta">
                  Get Started with AI Assessments
                </a>
              </div>

              <div class="shape shape-1">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z"
                    transform="translate(100 100)"
                  ></path>
                </svg>
              </div>

              <div class="shape shape-2">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z"
                    transform="translate(100 100)"
                  ></path>
                </svg>
              </div>

              <div class="dots dots-1">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <pattern
                    id="dot-pattern"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                  </pattern>
                  <rect
                    width="100"
                    height="100"
                    fill="url(#dot-pattern)"
                  ></rect>
                </svg>
              </div>

              <div class="dots dots-2">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <pattern
                    id="dot-pattern-2"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                  </pattern>
                  <rect
                    width="100"
                    height="100"
                    fill="url(#dot-pattern-2)"
                  ></rect>
                </svg>
              </div>

              <div class="shape shape-3">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M43.3,-57.1C57.4,-46.5,71.1,-32.6,75.3,-16.2C79.5,0.2,74.2,19.1,65.1,35.3C56,51.5,43.1,65,27.4,71.7C11.7,78.4,-6.8,78.3,-23.9,72.4C-41,66.5,-56.7,54.8,-65.4,39.2C-74.1,23.6,-75.8,4,-71.7,-13.2C-67.6,-30.4,-57.7,-45.2,-44.3,-56.1C-30.9,-67,-15.5,-74,0.7,-74.9C16.8,-75.8,33.7,-70.7,43.3,-57.1Z"
                    transform="translate(100 100)"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </section>
{/* 
        <section id="clients" className="clients section">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <Swiper {...swiperConfig} className="init-swiper">
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-1.png"
                  className="img-fluid"
                  alt="Client 1"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-2.png"
                  className="img-fluid"
                  alt="Client 2"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-3.png"
                  className="img-fluid"
                  alt="Client 3"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-4.png"
                  className="img-fluid"
                  alt="Client 4"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-5.png"
                  className="img-fluid"
                  alt="Client 5"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-6.png"
                  className="img-fluid"
                  alt="Client 6"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-7.png"
                  className="img-fluid"
                  alt="Client 7"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="assets/img/clients/client-8.png"
                  className="img-fluid"
                  alt="Client 8"
                />
              </SwiperSlide>
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>
        </section> */}

        <section
          id="testimonials"
          class="testimonials section light-background"
        >
          <div class="container section-title" data-aos="fade-up">
            <h2>What Our Clients Say</h2>
            <p>
              Real feedback from our valued clients who have experienced our
              exceptional services.
            </p>
          </div>

          <div class="container">
            <div class="row g-5">
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div class="testimonial-item">
                  <img src={per1} class="testimonial-img" alt="Client 1" />
                  <h3>Emily Brown</h3>
                  <h4>Entrepreneur</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i class="bi bi-quote quote-icon-left"></i>
                    <span>
                      "Absolutely outstanding! Their services helped transform
                      our business model, and the team's support is unmatched."
                    </span>
                    <i class="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div class="testimonial-item">
                  <img src={per2} class="testimonial-img" alt="Client 2" />
                  <h3>David Lee</h3>
                  <h4>Software Engineer</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-half"></i>
                  </div>
                  <p>
                    <i class="bi bi-quote quote-icon-left"></i>
                    <span>
                      "Incredible service! The team went above and beyond to
                      ensure my project was a success."
                    </span>
                    <i class="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div class="testimonial-item">
                  <img src={per3} class="testimonial-img" alt="Client 3" />
                  <h3>Sophia Turner</h3>
                  <h4>Marketing Director</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i class="bi bi-quote quote-icon-left"></i>
                    <span>
                      "A game-changer for us! Their strategies and insights led
                      to a significant boost in our online presence."
                    </span>
                    <i class="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div class="testimonial-item">
                  <img src={per4} class="testimonial-img" alt="Client 4" />
                  <h3>James Robinson</h3>
                  <h4>Freelance Designer</h4>
                  <div class="stars">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-half"></i>
                  </div>
                  <p>
                    <i class="bi bi-quote quote-icon-left"></i>
                    <span>
                      "Their innovative approach to design made a huge impact on
                      my portfolio. Highly recommend!"
                    </span>
                    <i class="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="stats" class="stats section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row gy-4">
              <div class="col-lg-3 col-md-6">
                <div class="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1500"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>AI Assessments Conducted</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="400"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>Clients Served</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1200"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>Hours of AI Training</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div class="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="85"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>Industries Served</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" class="services section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>AI Video Assessment Services</h2>
            <p>
              Our AI-driven video assessment services provide accurate,
              objective, and efficient candidate evaluations to help you make
              data-driven hiring decisions.
            </p>
          </div>

          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row g-4">
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-camera-video"></i>
                  </div>
                  <div>
                    <h3>AI-Powered Interview Analysis</h3>
                    <p>
                      Leverage AI technology to analyze video interviews in
                      real-time, providing objective insights into a candidate's
                      communication skills, body language, and overall
                      suitability for the role.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-person-check"></i>
                  </div>
                  <div>
                    <h3>Skill-Based Evaluation</h3>
                    <p>
                      Our AI assesses candidates based on specific skills
                      required for the role. It evaluates both verbal and
                      non-verbal communication, helping you find the most
                      qualified candidates with precision.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-graph-up-arrow"></i>
                  </div>
                  <div>
                    <h3>Real-Time Feedback & Scoring</h3>
                    <p>
                      Instantly evaluate candidates with AI-powered feedback and
                      scoring. Our system provides real-time insights on
                      responses, helping you make faster and more informed
                      decisions.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-award"></i>
                  </div>
                  <div>
                    <h3>Candidate Comparison & Ranking</h3>
                    <p>
                      AI-driven tools compare and rank candidates based on
                      predefined criteria, helping you identify the best-fit
                      candidates for your organization in a fraction of the
                      time.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="500">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-file-earmark-text"></i>
                  </div>
                  <div>
                    <h3>Customizable Questionnaires & Assessments</h3>
                    <p>
                      Create tailored interview questions and assessments to fit
                      your exact hiring needs. Our AI system evaluates responses
                      against your customized rubric to ensure a perfect match.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="600">
                <div class="service-card d-flex">
                  <div class="icon flex-shrink-0">
                    <i class="bi bi-calendar-check"></i>
                  </div>
                  <div>
                    <h3>Automated Follow-Up & Scheduling</h3>
                    <p>
                      Our platform automates the scheduling of follow-up
                      interviews and feedback sessions. It ensures a seamless
                      and efficient hiring process, saving time for both
                      candidates and recruiters.
                    </p>
                    <a href="service-details.html" class="read-more">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" class="pricing section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>
              Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
              consectetur velit
            </p>
          </div>

          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row g-4 justify-content-center">
              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <div class="pricing-card">
                  <h3>Basic Plan</h3>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="amount">9.9</span>
                    <span class="period">/ month</span>
                  </div>
                  <p class="description">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium totam.
                  </p>

                  <h4>Featured Included:</h4>
                  <ul class="features-list">
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Duis aute irure dolor
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Excepteur sint occaecat
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Nemo enim ipsam voluptatem
                    </li>
                  </ul>

                  <a href="#" class="btn btn-primary">
                    Buy Now
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                <div class="pricing-card popular">
                  <div class="popular-badge">Most Popular</div>
                  <h3>Standard Plan</h3>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="amount">19.9</span>
                    <span class="period">/ month</span>
                  </div>
                  <p class="description">
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum.
                  </p>

                  <h4>Featured Included:</h4>
                  <ul class="features-list">
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Lorem ipsum dolor sit amet
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Consectetur adipiscing elit
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Sed do eiusmod tempor
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Ut labore et dolore magna
                    </li>
                  </ul>

                  <a href="#" class="btn btn-light">
                    Buy Now
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
                <div class="pricing-card">
                  <h3>Premium Plan</h3>
                  <div class="price">
                    <span class="currency">$</span>
                    <span class="amount">39.9</span>
                    <span class="period">/ month</span>
                  </div>
                  <p class="description">
                    Quis autem vel eum iure reprehenderit qui in ea voluptate
                    velit esse quam nihil molestiae.
                  </p>

                  <h4>Featured Included:</h4>
                  <ul class="features-list">
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Temporibus autem quibusdam
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Saepe eveniet ut et voluptates
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Nam libero tempore soluta
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Cumque nihil impedit quo
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i>
                      Maxime placeat facere possimus
                    </li>
                  </ul>

                  <a href="#" class="btn btn-primary">
                    Buy Now
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="faq-9 faq section light-background" id="faq">
          <div class="container">
            <div class="row">
              <div class="col-lg-5" data-aos="fade-up">
                <h2 class="faq-title">Have a question? Check out the FAQ</h2>
                <p class="faq-description">
                  Find answers to common questions about our AI video interview
                  platform. If you have more questions, reach out via our
                  contact form below.
                </p>
                <div
                  class="faq-arrow d-none d-lg-block"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <svg
                    class="faq-arrow"
                    width="200"
                    height="211"
                    viewBox="0 0 200 211"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>

              <div class="col-lg-7" data-aos="fade-up" data-aos-delay="300">
                <div class="faq-container">
                  <div class="faq-item faq-active">
                    <h3>What is an AI video interview recruitment platform?</h3>
                    <div class="faq-content">
                      <p>
                        An AI video interview recruitment platform is a
                        technology-driven solution that facilitates the
                        screening and evaluation of job candidates through video
                        interviews.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      How does an AI video interview recruitment platform work?
                    </h3>
                    <div class="faq-content">
                      <p>
                        Candidates participate in video interviews where they
                        answer pre-recorded or live interview questions. The
                        platform then uses AI algorithms to analyze various
                        aspects of their responses, such as communication
                        skills, problem-solving abilities, and personality
                        traits.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      What are the benefits of using an AI video interview
                      recruitment platform?
                    </h3>
                    <div class="faq-content">
                      <p>
                        FPO. We can use this section to build confidence and
                        trust by displaying partner and award logos paired with
                        a strong case studyvideo of a commercial or residential
                        project.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>Why does the company use AI video interviews?</h3>
                    <div class="faq-content">
                      <p>
                        Efficiency: AI video interviews streamline the
                        recruitment process, allowing us to efficiently screen a
                        larger pool of candidates. They help eliminate
                        unconscious bias and ensure a fairer, more consistent
                        assessment.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      How does AI video assessment help in evaluating
                      candidates?
                    </h3>
                    <div class="faq-content">
                      <p>
                        AI video assessment uses advanced algorithms to analyze
                        both verbal and non-verbal cues during a candidate's
                        video interview. The system evaluates key aspects such
                        as speech clarity, body language, facial expressions,
                        and tone of voice. This helps recruiters gain an
                        objective, unbiased evaluation of each candidate's
                        communication skills and overall suitability for the
                        role, improving the quality of hiring decisions.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      Can the AI video assessment be customized for specific job
                      roles?
                    </h3>
                    <div class="faq-content">
                      <p>
                        Yes, the AI video assessment platform allows
                        customization based on the specific requirements of each
                        job role. You can create tailored interview questions
                        and set criteria for evaluating candidates, ensuring
                        that the assessment is relevant to the role you’re
                        hiring for. This flexibility helps ensure that you
                        evaluate candidates on the skills and competencies that
                        matter most for your organization.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="call-to-action-2"
          class="call-to-action-2 section dark-background"
        >
          <div class="container">
            <div
              class="row justify-content-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-10">
                <div class="text-center">
                  <h3>
                    Unlock Smarter Hiring with AI-Driven Video Assessments
                  </h3>
                  <p>
                    Discover how AI-powered video assessments can help you
                    evaluate candidates faster and more accurately. From
                    automated scoring to real-time analysis, our AI technology
                    takes the guesswork out of hiring. Empower your recruitment
                    team to make data-backed decisions and choose the right fit
                    for your company.
                  </p>
                  <a class="cta-btn" href="#learn-more">
                    Learn More About AI Video Assessments
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Contact</h2>
            <p>
              We value your feedback and are here to assist you with any
              inquiries you may have regarding Firstlevel AI Video Interview.
              Whether you need technical support, have questions about our
              services, or require assistance with your account, our dedicated
              team is ready to help.
            </p>
          </div>

          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row g-4 g-lg-5">
              <div class="col-lg-5">
                <div class="info-box" data-aos="fade-up" data-aos-delay="200">
                  <h3>Contact Info</h3>
                  <p>Feel free to get in touch with us.</p>

                  <div
                    class="info-item"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div class="icon-box">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="content">
                      <h4>Our Location</h4>
                      <p>A108 Adam Street</p>
                      <p>New York, NY 535022</p>
                    </div>
                  </div>

                  <div
                    class="info-item"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div class="icon-box">
                      <i class="bi bi-telephone"></i>
                    </div>
                    <div class="content">
                      <h4>Phone Number</h4>
                      <p>+1 5589 55488 55</p>
                      <p>+1 6678 254445 41</p>
                    </div>
                  </div>

                  <div
                    class="info-item"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <div class="icon-box">
                      <i class="bi bi-envelope"></i>
                    </div>
                    <div class="content">
                      <h4>Email Address</h4>
                      <p>info@example.com</p>
                      <p>contact@example.com</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7">
                <div
                  class="contact-form"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <h3>Get In Touch</h3>
                  <p>
                    Have questions about how AI-powered video assessments can
                    transform your recruitment process? We're here to help!
                    Whether you're ready to start using AI assessments or just
                    need more information, our team is here to assist you.
                  </p>

                  <form
                    action="forms/contact.php"
                    method="post"
                    class="php-email-form"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div class="row gy-4">
                      <div class="col-md-6">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          placeholder="Your Name"
                          required=""
                        />
                      </div>

                      <div class="col-md-6 ">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          placeholder="Your Email"
                          required=""
                        />
                      </div>

                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          name="subject"
                          placeholder="Subject"
                          required=""
                        />
                      </div>

                      <div class="col-12">
                        <textarea
                          class="form-control"
                          name="message"
                          rows="6"
                          placeholder="Message"
                          required=""
                        ></textarea>
                      </div>

                      <div class="col-12 text-center">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your message has been sent. Thank you!
                        </div>

                        <button type="submit" class="btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer" class="">
        <div class="">
          <Container>
            <Content>
              <Row>
                <LogoContainer>
                  {/* <LogoImg src={logo} /> */}
                  <LogoText>
                    Firstlevel<span style={{ color: 'orange' }}>AI</span>
                  </LogoText>
                </LogoContainer>
                <LinksContainer>
                  <Link href="/">Home</Link>
                  <Link href="/aboutUs">About</Link>
                  <Link href="/contactUs">Contact</Link>
                  <Link href="/pricing">Pricing</Link>

                  <Link href="/securityCenter">Security Center</Link>
                  <Link href="/termsAndCondition">Terms And Condition</Link>
                  <Link href="/privacypolicy">Privacy Policy</Link>
                </LinksContainer>
                <SocialLinksContainer>
                  <SocialLink href="https://facebook.com">
                    <FacebookIcon />
                  </SocialLink>
                  <SocialLink href="https://twitter.com">
                    <TwitterIcon />
                  </SocialLink>
                  <SocialLink href="https://youtube.com">
                    <YoutubeIcon />
                  </SocialLink>
                </SocialLinksContainer>
                <CopyrightText>
                  &copy; Copyright 2020, Treact Inc. All Rights Reserved.
                </CopyrightText>
              </Row>
            </Content>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default NewHomePage;
