// import React, { useState, useEffect } from "react";
// import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";

// import "./Interview.css";
// import VideoCapture from "../../Components/VideoCapture/VideoCapture";
// import axios from "axios";
// import PracticalInterviewFeedback from "../PracticalInterviewFeedback/PracticalInterviewFeedback";
// import Loader from "../../Components/Loader/Loader";
// import { useSelector } from "react-redux";

// function Interview({ questions, answers, title }) {
//   const { speak } = useSpeechSynthesis();
//   const [candidateAnswers, setCandidateAnswers] = useState(
//     Array(questions.length).fill("")
//   );
//   const [questionIndex, setQuestionIndex] = useState(-1);
//   const [isListening, setIsListening] = useState(false);
//   const [interviewStarted, setInterviewStarted] = useState(false);
//   const [ans, setAns] = useState("");
//   const [completed, setCompleted] = useState(false);
//   const [suggestionsQ, setSuggestionsQ] = useState([]);
//   const [suggestionsA, setSuggestionsA] = useState([]);
//   const [suggestionsR, setSuggestionsR] = useState([]);
//   const [videoId, setVideoId] = useState('');

//   const token = useSelector((state) => state.token.token);
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const { listen, stop, listening } = useSpeechRecognition({
//     onResult: async (result) => {
//       console.log(result);
//       await setAns(result);

//       if (
//         result.toLowerCase().includes("repeat again") ||
//         result.toLowerCase().includes("say that again") ||
//         result.toLowerCase().includes("can you repeat")
//       ) {
//         console.log("Previous index : ", questionIndex);
//         const updatedIndex = questionIndex - 1; // Get the updated value
//         SpeakQuestion(updatedIndex);
//       }
//     },
//     onEnd: async () => {
//       console.log("answer is = ", ans);
//       const updatedAnswers = [...candidateAnswers];
//       updatedAnswers[questionIndex] = ans;
//       await setCandidateAnswers(updatedAnswers);
//     },
//   });

//   const startInterview = () => {
//     if (!interviewStarted) {
//       setInterviewStarted(true);
//     }
//     const res = SpeakQuestion(questionIndex);
//     console.log("its res", res);
//     if (res === true) {
//       listen();
//       console.log("interview ", listening);
//     }
//   };

//   const SpeakQuestion = (index) => {
//     stop();
//     console.log("speak question ", listening);
//     index = index + 1;
//     console.log("question index ", index);
//     setQuestionIndex(index);
//     if (index >= 0 && index < questions.length) {
//       speak({ text: questions[index] + "you can say your answer now" });
//       setTimeout(() => {
//         listen();
//       }, 1000);
//       return true;
//     } else {
//       speak({ text: "Interview ended Thank you" });
//       console.log("these are answers ", candidateAnswers);
//       setCompleted(true);
//       getSuggestions();
//       return false;
//     }
//   };

//   const getSuggestions = async () => {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_INTERVIEW_SUGGESTIONS}`,
//       {
//         gptAnswes: answers,
//         candidateAnswers: candidateAnswers,
//         questions: questions,
//       },
//       config
//     );
//     if (response.status === 200) {
//       const question = await response.data.question;
//       const answer = await response.data.answers;
//       const recomendations = await response.data.recommendations;

//       setSuggestionsQ(question);
//       setSuggestionsA(answer);
//       setSuggestionsR(recomendations);
//       setCompleted(false);
//     }
//     console.log(response);
//   };

//   return (
//     <div>
//       {completed ? <Loader visible={completed} style={{ flex: 1 }} /> : null}
//       {suggestionsQ.length === 0 &&
//       suggestionsA.length === 0 &&
//       suggestionsR.length === 0 &&
//       videoId ===''? (
//         <>
//           {questionIndex > -1 && questionIndex < questions.length ? (
//             <div className=" d-flex flex-column align-items-start  ">
//               <p className="question-text my-0">
//                 Question {questionIndex + 1}:
//               </p>
//               <div className="question-container w-100 d-flex flex-column align-items-start ">
//                 <p className="question-detail">{questions[questionIndex]}</p>
//               </div>
//             </div>
//           ) : null}
//           {completed ? (
//             <div>
//               <h2>Interview Completed</h2>
//               <ul>
//                 {questions.map((question, i) => {
//                   <li key={i}>
//                     <strong>Question:</strong> {question}
//                     <br />
//                     <strong>Answer:</strong>{" "}
//                     {candidateAnswers[i] || "No answer given"}
//                   </li>;
//                 })}
//               </ul>
//             </div>
//           ) : null}
//           <div className="py-2 px-3 ">
//             <VideoCapture
//               capture={completed}
//               className="VideoCapture"
//               title={title}
//               setVideoId={setVideoId}
//             />
//             <div className="button-container">
//               <button className="button" onClick={startInterview}>
//                 {interviewStarted ? "Next Question" : "Start Interview"}
//               </button>
//             </div>
//           </div>
//         </>
//       ) : (
//         <PracticalInterviewFeedback
//           question={suggestionsQ}
//           answer={suggestionsA}
//           candidateAnswers={candidateAnswers}
//           recomendations={suggestionsR}
//           videoId= {videoId}
//         />
//       )}
//     </div>
//   );
// }

// export default Interview;

import React, { useState, useEffect } from "react";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";

import "./Interview.css";
import VideoCapture from "../VideoCapture/VideoCapture";
import axios from "axios";
import PracticalInterviewFeedback from "../PracticalInterviewFeedback/PracticalInterviewFeedback";
// import Loader from "../../components/Loader/Loader";
import Loader from "../../components/Loader/Loader";

import { useSelector } from "react-redux";

function Interview({ questions, answers, title }) {
  const { speak } = useSpeechSynthesis();
  const [candidateAnswers, setCandidateAnswers] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [isListening, setIsListening] = useState(false);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [ans, setAns] = useState("");
  const [completed, setCompleted] = useState(false);
  const [suggestionsQ, setSuggestionsQ] = useState([]);
  const [suggestionsA, setSuggestionsA] = useState([]);
  const [suggestionsR, setSuggestionsR] = useState([]);
  const [videoId, setVideoId] = useState("");

  // const token = useSelector((state) => state?.token?.token);

  const token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { listen, stop, listening } = useSpeechRecognition({
    onResult: async (result) => {
      console.log(result);
      await setAns(result);

      if (
        result.toLowerCase().includes("repeat again") ||
        result.toLowerCase().includes("say that again") ||
        result.toLowerCase().includes("can you repeat")
      ) {
        console.log("Previous index : ", questionIndex);
        const updatedIndex = questionIndex - 1; // Get the updated value
        SpeakQuestion(updatedIndex);
      }
    },
    onEnd: async () => {
      console.log("answer is = ", ans);
      const updatedAnswers = [...candidateAnswers];
      updatedAnswers[questionIndex] = ans;
      await setCandidateAnswers(updatedAnswers);
    },
  });

  const startInterview = () => {
    if (!interviewStarted) {
      setInterviewStarted(true);
    }
    const res = SpeakQuestion(questionIndex);
    console.log("its res", res);
    if (res === true) {
      listen();
      console.log("interview ", listening);
    }
  };

  const SpeakQuestion = (index) => {
    stop();
    console.log("speak question ", listening);
    index = index + 1;
    console.log("question index ", index);
    setQuestionIndex(index);
    if (index >= 0 && index < questions.length) {
      speak({ text: questions[index] + "you can say your answer now" });
      setTimeout(() => {
        listen();
      }, 1000);
      return true;
    } else {
      speak({ text: "Interview ended Thank you" });
      console.log("these are answers ", candidateAnswers);
      setCompleted(true);
      getSuggestions();
      return false;
    }
  };

  const getSuggestions = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_INTERVIEW_SUGGESTIONS}`,
      {
        gptAnswes: answers,
        candidateAnswers: candidateAnswers,
        questions: questions,
      },
      config
    );
    if (response.status === 200) {
      const question = await response.data.question;
      const answer = await response.data.answers;
      const recomendations = await response.data.recommendations;

      setSuggestionsQ(question);
      setSuggestionsA(answer);
      setSuggestionsR(recomendations);
      setCompleted(false);
    }
    console.log(response);
  };

  return (
    <div>
      {completed ? <Loader visible={completed} style={{ flex: 1 }} /> : null}
      {suggestionsQ.length === 0 &&
      suggestionsA.length === 0 &&
      suggestionsR.length === 0 &&
      videoId === "" ? (
        <>
          {questionIndex > -1 && questionIndex < questions.length ? (
            <div className=" d-flex flex-column align-items-start  ">
              <p className="question-text my-0">
                Question {questionIndex + 1}:
              </p>
              <div className="question-container w-100 d-flex flex-column align-items-start ">
                <p className="question-detail">{questions[questionIndex]}</p>
              </div>
            </div>
          ) : null}
          {completed ? (
            <div>
              <h2>Interview Completed</h2>
              <ul>
                {questions.map((question, i) => {
                  return (
                    <li key={i}>
                      <strong>Question:</strong> {question}
                      <br />
                      <strong>Answer:</strong>{" "}
                      {candidateAnswers[i] || "No answer given"}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          <div className="py-2 px-3 ">
            <VideoCapture
              capture={completed}
              className="VideoCapture"
              title={title}
              setVideoId={setVideoId}
            />
            <div className="button-container">
              <button className="button" onClick={startInterview}>
                {interviewStarted ? "Next Question" : "Start Interview"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <PracticalInterviewFeedback
          question={suggestionsQ}
          answer={suggestionsA}
          candidateAnswers={candidateAnswers}
          recomendations={suggestionsR}
          videoId={videoId}
        />
      )}
    </div>
  );
}

export default Interview;
