import { createSlice } from '@reduxjs/toolkit'
const initialState = { role: undefined }

const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        changeRole(state, action) {
            console.log("action=", action)
            state.role = action.payload.role
            return state
        }
    }
})
export default roleSlice.reducer
export const roleActions = roleSlice.actions

//export const { changeRole } = roleSlice.actions