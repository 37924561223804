import React, { useState, useCallback, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import "./VideoCapture.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VideoCapture = ({ capture, title, setVideoId }) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  // const token = useSelector((state) => state?.token?.token);

  const token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    try {
      if (webcamRef.current && webcamRef.current.stream) {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();
        toast.success("Video recording started start interview", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        console.error("Webcam not initialized");
      }
    } catch (err) {
      toast.error(err, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);

      if (recordedChunks.length) {
        try {
          const blob = new Blob(recordedChunks, {
            type: "video/webm",
          });
          const videoSizeInBytes = blob.size;
          const videoSizeInMB = videoSizeInBytes / (1024 * 1024);

          console.log("Video size:", videoSizeInBytes, "bytes");
          console.log("Video size:", videoSizeInMB, "MB");

          const formData = new FormData();
          formData.append("video", blob);
          formData.append("title", title);
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VIDEO_UPLOAD}`,
            formData,
            config
          );
          console.log("Backend response:", response.data);
          const videoId = response.data.video._id;
          setVideoId(videoId);
          console.log("videoId set !!! ", videoId);
        } catch (error) {
          console.error("Error sending video to backend:", error);
        } finally {
          setRecordedChunks([]);
        }
      }
    } else {
      console.error("MediaRecorder not initialized");
    }
  }, [mediaRecorderRef, setCapturing, recordedChunks, setRecordedChunks]);

  useEffect(() => {
    if (capture === true) {
      handleStopCaptureClick();
    }
  }, [capture, handleStopCaptureClick]);

  const videoConstraints = {
    width: 900,
    height: 400,
    facingMode: "user",
  };

  // const handleDownload = useCallback(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/webm",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     document.body.appendChild(a);
  //     a.style = "display: none";
  //     a.href = url;
  //     a.download = "react-webcam-stream-capture.webm";
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     setRecordedChunks([]);
  //   }
  // }, [recordedChunks]);

  // const videoConstraints = {
  //   width: 900,
  //   height: 400,
  //   facingMode: "user",
  // };

  return (
    <>
      {/* <div className="Container"> */}
      {/* {capturing ? (
          <>
            <button
              className="button"
              style={{ marginRight: 3 }}
              onClick={handleStopCaptureClick}
            >
              <i className="fas fa-stop"></i> Stop Capture
            </button>

            <button className="button" onClick={handleDownload}>
              <i className="fas fa-stop"></i> Download
            </button>
          </>
        ) : (
          <button className="button" onClick={handleStartCaptureClick}>
            <i className="fas fa-play"></i> Start Capture
          </button>
        )} */}
      {/* </div> */}
      <Webcam
        audio={true}
        mirrored={true}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        style={{ border: "2px solid black", borderRadius: 10 }}
        muted={true}
        onUserMedia={() => handleStartCaptureClick()}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default VideoCapture;
