import { createSlice } from '@reduxjs/toolkit'
import { createStore } from 'redux'
const initialState = { token: undefined }

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        changeToken(state, action) {
            console.log("action=", action)
            state.token = action.payload.token
            return state
        }
    }
})
export default tokenSlice.reducer;
export const tokenActions = tokenSlice.actions

// const store = createStore(tokenSlice.reducer)
// export default store

//const { changeToken } = tokenSlice.actions
//export { changeToken, tokenSlice }