import React from "react";
import { MutatingDots } from "react-loader-spinner";
import './Loader.css'
export default function Loader({ visible }) {
  return (
    visible &&
    <div className="loader-overlay">
      <div className="loader-container">
        <MutatingDots
          height="100"
          width="100"
          color="#EB6B3C"
          secondaryColor="#EB6B3C"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          // wrapperStyle={{}}
          // wrapperClass=""
          visible={visible}
        />
      </div>
    </div>
  );
}
