// chakra imports
import { Box, Flex, Stack,Button } from "@chakra-ui/react";

//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// FUNCTIONS

function SidebarContent(props) {
  const logout = async () => {
    const token = localStorage.getItem('Token');
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGOUT}`,
        {
          withCredentials: true,
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        },
      );
      // Cookies.remove('jwt');
      localStorage.removeItem('Token');
      localStorage.removeItem('role');
      setTimeout(() => {
        navigate('/login');
        // window.location.reload();
      }, []);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const { routes } = props;
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'
        textAlign='center'>
       
        {/* <SidebarCard /> */}
        <Button style={{backgroundColor:"#3590f0",color:"white"}} onClick={()=> logout()} >Logout</Button>
      </Box>
    </Flex>
  );
}

export default SidebarContent;
