import React from 'react'

const SpinnerLoader = () => {
    return (

        <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
            </div>
        </div>
    )
}

export default SpinnerLoader