import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdAdsClick,
  MdAssignmentInd,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import Candidates from 'components/Pages/Candidates';
import RTL from 'views/admin/rtl';
import InterviewScore from 'components/Pages/InterviewScore';
import Notification from 'components/Pages/Notification';
import SheduleInterview from 'components/Pages/SheduleInterview';
import Jobs from 'components/Pages/Jobs';
// import Home from "./Pages/Home/Home"

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import PostJob from 'components/Pages/PostJob';

const routes = [
  
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Post Job',
    layout: '/admin',
    path: '/postJobs',
    icon: <Icon as={MdAdsClick} width="20px" height="20px" color="inherit" />,
    component: <PostJob />,
  },
  {
    name: 'Jobs',
    layout: '/admin',
    path: '/jobs',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <Jobs />,
    secondary: true,
  },
  {
    name: 'Applied Candidates',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/candidates',
    component: <Candidates />,
  },
  {
    name: 'Schedule Interview.',
    layout: '/admin',
    path: '/scheduleInterview',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <SheduleInterview />,
  },
  {
    name: 'Interview Result',
    layout: '/admin',
    path: '/interviewScore',
    icon: <Icon as={MdAdsClick} width="20px" height="20px" color="inherit" />,
    component: <InterviewScore />,
  },
  {
    name: 'Notification',
    layout: '/admin',
    path: '/notification',
    icon: (
      <Icon as={MdAssignmentInd} width="20px" height="20px" color="inherit" />
    ),
    component: <Notification />,
  },

  // {
  //   name: 'Sign In',
  //   layout: '/auth',
  //   path: '/sign-in',
  //   icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //   component: <SignInCentered />,
  // },
  // {
  //   name: 'RTL Admin',
  //   layout: '/rtl',
  //   path: '/rtl-default',
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: <RTL />,
  // },
];

export default routes;
