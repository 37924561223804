import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './styles/tailwind.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
// // Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";
// // Bootstrap Bundle JS
// import "bootstrap/dist/js/bootstrap.bundle.min";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import store from './Utilities/redux/main';
// import ErrorBoundary from "./Pages/ErrorBoundary/ErrorBoundary";
import { queryClient } from './Utilities/queryClient';
import './styles/tailwind.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(
  process.env.REACT_APP_LOGIN,
  ' aaaaa =',
  process.env.REACT_APP_CLIENT_ID_GOOGLE,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider
          clientId={`${process.env.REACT_APP_CLIENT_ID_GOOGLE}`}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
          ,
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
