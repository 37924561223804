import React, { useState } from 'react';
import './Home/PostJob.css';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const PostJob = () => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('onsite');
  const [link, setLink] = useState('');
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
  const [requirements, setRequirements] = useState('');
  const [location, setLocation] = useState('');
  const token = localStorage.getItem('Token');
  const [jobLoader, setJobLoader] = useState(false);
  const navigate = useNavigate();
  const postJob = async () => {
    setJobLoader(true);
    try {
      const body = {
        title: title,
        description: description,
        type: type,
        requirements: requirements,
        responsibilities: responsibilities,
        location: location,
        status: 'Active',
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CREATE_JOB_LINK}`,
        body,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setLink(data);
      setJobLoader(false);
      toast.success('Job posted successfully');
      navigate('/admin/jobs');
    } catch (error) {
      console.log(error);
      setJobLoader(false);
    }
  };
  const complteWithAI = async () => {
    setLoader(true);
    try {
      if (!title && !description) {
        toast.error('Please enter Job title & description');
        setLoader(false);

        return;
      }
      const payload = {
        jobTitle: title,
        description: description,
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GENERATE_RR}`,
        payload,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setLoader(false);
      console.log(data);
      console.log('responsi', data?.responsibilities);

      setResponsibilities(data?.responsibilities);
      setRequirements(data?.requirements);
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error('Internal Server Error');
    }
  };
  console.log(responsibilities);

  return (
    <React.Fragment>
      <Toaster position="top-center" reverseOrder={false} />
      <Row>
        <Col>
          <Card style={{ marginTop: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Post Job</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <Card.Body>
              <div className="post-job-container">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title">Post Job</h1>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label className="form-label">Job Title</label>
                        <input
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Job Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>

                      <button
                        className="ai-button"
                        disabled={loader}
                        onClick={() => complteWithAI()}
                      >
                        {loader ? 'Fetching' : 'Complete with AI'}
                      </button>

                      <div className="form-group">
                        <label className="form-label">Requirements</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          value={requirements}
                          onChange={(e) => setRequirements(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Responsibilities</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          value={responsibilities}
                          onChange={(e) => setResponsibilities(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Location</label>
                        <input
                          className="form-control"
                          type="text"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">Job Type</label>
                        <select
                          className="form-select"
                          value={location}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="onsite">Onsite</option>
                          <option value="remote">Remote</option>
                          <option value="hybrid">Hybrid</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="form-label">Link of Job</label>
                        <input
                          className="form-control"
                          type="text"
                          readOnly
                          placeholder="Generated Link"
                          value={link}
                          // onChange={(e) => setLink(e.target.value)}
                          onClick={() => {
                            navigator.clipboard.writeText(link);
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      {/*<button className="cancel-button">Cancel</button>*/}

                      <button
                        className="save-button"
                        onClick={() => postJob()}
                        disabled={jobLoader}
                      >
                        {jobLoader ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PostJob;
