import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineDashboard,
  MdPeopleAlt,
  MdCancel,
  MdOutlinePauseCircle,
  MdOutlinePersonOff,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ColumnsTable from 'views/admin/dataTables/components/ColumnsTable';
import ComplexTable from 'views/admin/dataTables/components/ComplexTable';
import tableDataColumns from 'views/admin/dataTables/variables/tableDataColumns.json';
import tableDataComplex from 'views/admin/dataTables/variables/tableDataComplex.json';
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from 'views/admin/dataTables/variables/columnsData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Jobs = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [jobs, setJobs] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    Active: 0,
    Inactive: 0,
    Hold: 0,
    Closed: 0,
  });
  const token = localStorage.getItem('Token');

  const getJobs = async () => {
    try {
      //REACT_APP_GET_JOBS
      if (token) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_JOBS}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(data.jobs);
        setJobs(data?.jobs);
        const counts = data?.jobs.reduce((acc, job) => {
          acc[job.status] = (acc[job.status] || 0) + 1;
          return acc;
        }, {});
        setStatusCounts({
          Active: counts.Active || 0,
          Inactive: counts.Inactive || 0,
          Hold: counts.Hold || 0,
          Closed: counts.Closed || 0,
        });
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getJobs();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Jobs</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <div
              className="report-box"
              // style={{
              //   display: 'flex',
              //   justifyContent: 'space-around',
              //   alignItems: 'center',
              //   width: '100%',
              //   padding: '20px',
              //   backgroundColor: '#f0f0f0',
              //   borderRadius: '8px',
              //   marginBottom: '20px',
              //   marginTop: '25px',
              // }}
            >
              {/*              
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'orange',
                  }}
                >
                  Hold
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                 
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  Reject
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                  :2
                </div>
              </div>

              
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  Accept
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                 :3
                </div>
              </div> */}
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlineDashboard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Active"
                  // growth="+23%"
                  value={statusCounts.Active}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePersonOff}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Inactive"
                  // growth="+23%"
                  value={statusCounts.Inactive}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdOutlinePauseCircle}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="Hold"
                  value={statusCounts.Hold}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdCancel}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  // growth="+23%"
                  name="closed"
                  value={statusCounts.Closed}
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Active
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="inActive" style={{ color: 'blue' }}>
                    InActive
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Hold
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Closed
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
            </Card.Header>
            {/*     <Card.Body>
           <NestedTable tab={activeTab} /> */}
            {/* <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </Table> */}
            <Card.Body>
              {jobs.filter((job) =>
                activeTab === 'active'
                  ? job.status === 'Active'
                  : activeTab === 'inActive'
                  ? job.status === 'Inactive'
                  : activeTab === 'hold'
                  ? job.status === 'Hold'
                  : job.status === 'Closed',
              ).length > 0 ? (
                <ColumnsTable
                  columnsData={columnsDataColumns}
                  tableData={jobs.filter((job) =>
                    activeTab === 'active'
                      ? job.status === 'Active'
                      : activeTab === 'inActive'
                      ? job.status === 'Inactive'
                      : activeTab === 'hold'
                      ? job.status === 'Hold'
                      : job.status === 'Closed',
                  )}
                />
              ) : (
                <p>No jobs available for the selected status.</p>
              )}
            </Card.Body>
            {/* <ComplexTable
                columnsData={columnsDataComplex}
                tableData={tableDataComplex}
              /> 
            </Card.Body>*/}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Jobs;
